import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';

import { LinkButtonThemes } from '@/components/buttons/LinkButtonThemes';
import { SecondaryLinkButton } from '@/components/buttons/SecondaryLinkButton';
import { useAuthData } from '@/hooks/useAuthData';
import { useRouter } from '@/hooks/useRouter';
import { usePathname } from '@/i18n/navigation';
import { Claims, Scopes } from '@/lib/users/claims';
import { Keys } from '@/models/Keyboard.enum';
import { COOKIE_KEY_LOCALE, setCookieByKey } from '@/utils/cookies';
import { Locale } from '@/utils/type-utils';

import { DesktopMenuItem } from './DesktopMenuItem';
import NuveenDesktopMenuItems from './NuveenDesktopMenuItems';
import StandardDesktopMenuItems from './StandardDesktopMenuItems';
import { UserModals } from './UserModal';
import { MENU, MenuItem, NUVEEN_SUPPLIER_MENU } from './constants';

import clsx from 'clsx';

type GlobalNavProps = {
  isTransparentMode: boolean;
  openModal: (key: UserModals) => void;
  isNuveenSupplier?: boolean;
};

export default function GlobalNav({
  isTransparentMode,
  openModal,
  isNuveenSupplier = false,
}: GlobalNavProps) {
  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations('nav');
  const { isAuthenticated, checkClaims } = useAuthData();

  const showMemberPortalMenu =
    checkClaims(Scopes.ClientAccess, [Claims.CanAccessMemberPortal]) || false;

  const isHome = pathname === '/';
  const menuRef = useRef<HTMLUListElement>(null);

  const [subMenuOpen, setSubMenuOpen] = useState('');

  const toggleSubMenu = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItem
  ) => {
    if (!menuItem.subMenu) return;
    e.preventDefault();

    if (menuItem.key === subMenuOpen) {
      setSubMenuOpen('');
    } else {
      setSubMenuOpen(menuItem.key);
    }
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItem
  ) => {
    if (e.key === Keys.Enter) {
      toggleSubMenu(e, menuItem);
    }
  };

  const handleLocaleClick = (menu: MenuItem) => {
    setSubMenuOpen('');
    setCookieByKey(COOKIE_KEY_LOCALE, menu.locale as Locale);
  };

  const handleUserLinkClick = (menu: MenuItem) => {
    setSubMenuOpen('');
    openModal(menu.key as UserModals);
  };

  const handleAccountSubMenuClick = async (
    menu: MenuItem,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setSubMenuOpen('');
    if (menu.key === 'logout') {
      event.preventDefault();
      const { logout } = await import('@/lib/authentication/helpers');
      logout();
      await router.replace('/');
      window.location.reload();
    }
  };

  useEffect(() => {
    if (subMenuOpen === '') return;
    function handleClick(event: MouseEvent) {
      const clickTarget = event.target;
      if (
        clickTarget instanceof HTMLElement &&
        menuRef.current &&
        !menuRef.current.contains(clickTarget)
      ) {
        setSubMenuOpen('');
      }
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [subMenuOpen]);

  useEffect(() => {
    if (isTransparentMode && isHome && subMenuOpen !== '') {
      setSubMenuOpen('');
    }
  }, [isTransparentMode, isHome]);

  const desktopMenu = MENU.filter(
    (item) => item.showInDesktop === true || item.showInDesktop === undefined
  );

  const nuveenSupplierMenu = NUVEEN_SUPPLIER_MENU.filter(
    (item) => item.showInDesktop === true || item.showInDesktop === undefined
  );

  const menu = isNuveenSupplier ? nuveenSupplierMenu : desktopMenu;

  const showPrimaryCta =
    (isAuthenticated && !showMemberPortalMenu) || !isAuthenticated;

  return (
    <nav
      className="flex items-center justify-end flex-1 h-full ml-20 lg:ml-30 xl:ml-40"
      aria-label="main-menu-label"
    >
      <ul
        className={clsx(
          'flex items-stretch h-full mr-8 space-x-2 text-base leading-4 max-lg:hidden xl:space-x-12 xl:mr-12 text-teal-5 w-full',
          isTransparentMode && '!text-white',
          isNuveenSupplier ? 'justify-end' : 'justify-between'
        )}
        role="menubar"
        aria-orientation="horizontal"
        ref={menuRef}
        onMouseLeave={() => setSubMenuOpen('')}
      >
        {!isAuthenticated && isNuveenSupplier
          ? null
          : menu.map((menuItem) => (
              <DesktopMenuItem
                key={menuItem.key}
                menuItem={menuItem}
                isCurrentRoute={
                  pathname === menuItem.url ||
                  !!(
                    menuItem.subMenu &&
                    !isTransparentMode &&
                    menuItem.subMenu.some((subItem) => pathname === subItem.url)
                  )
                }
                withCaret={menuItem.subMenu !== undefined}
                isOpen={subMenuOpen === menuItem.key}
                isTransparentMode={isTransparentMode}
                widthSubMenu={menuItem.widthSubMenu}
                onKeyDown={onKeyDown}
                onMouseEnter={toggleSubMenu}
              />
            ))}
        {isNuveenSupplier ? (
          <NuveenDesktopMenuItems
            isTransparentMode={isTransparentMode}
            isSubMenuOpen={subMenuOpen}
            onKeyDown={onKeyDown}
            onMouseEnter={toggleSubMenu}
            onSubMenuClick={handleUserLinkClick}
            onAccountSubMenuClick={handleAccountSubMenuClick}
          />
        ) : (
          <StandardDesktopMenuItems
            isSubMenuOpen={subMenuOpen}
            isTransparentMode={isTransparentMode}
            onKeyDown={onKeyDown}
            onMouseEnter={toggleSubMenu}
            onAccountSubMenuClick={handleAccountSubMenuClick}
            onSubMenuClick={handleUserLinkClick}
            onLocaleMenuClick={handleLocaleClick}
          />
        )}
      </ul>

      {showPrimaryCta && !isNuveenSupplier && (
        <SecondaryLinkButton
          href="/location-form"
          className="leading-3 shrink-0"
          theme={
            isTransparentMode ? LinkButtonThemes.White : LinkButtonThemes.Home
          }
          data-testid="nav-join-now"
        >
          {isAuthenticated ? t('book-tour') : t('join')}
        </SecondaryLinkButton>
      )}
    </nav>
  );
}
