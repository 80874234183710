import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Link } from '@/components/Link';
import checkmarkIcon from '@/public/icons/checkmarkIcon.svg';

import MenuItemLink from './MenuItemLink';
import { NewTag } from './NewTag';
import { EMPTY_URL, MenuItem as MenuItemType } from './constants';

import clsx from 'clsx';

type DesktopMenuItemProps = {
  menuItem: MenuItemType;
  isOpen: boolean;
  isCurrentRoute: boolean;
  withCaret?: boolean;
  isTransparentMode?: boolean;
  withBorder?: boolean;
  icon?: React.ReactNode;
  widthSubMenu?: number;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onMouseEnter: (
    e: React.MouseEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onSubMenuClick?: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  className?: string;
};

const DEFAULT_WIDTH_SUB_MENU = 196;

export function DesktopMenuItem({
  menuItem,
  isOpen,
  isCurrentRoute,
  withCaret,
  isTransparentMode,
  withBorder,
  icon,
  widthSubMenu,
  onKeyDown,
  onMouseEnter,
  onSubMenuClick,
  className,
}: DesktopMenuItemProps) {
  const t = useTranslations('nav');

  return (
    <li
      role="menuitem"
      key={menuItem.key}
      className="cursor-pointer"
      aria-haspopup={!!menuItem.subMenu}
      aria-expanded={isOpen}
    >
      <MenuItemLink
        isOpen={isOpen}
        isCurrentRoute={isCurrentRoute}
        menuItem={menuItem}
        icon={icon}
        withCaret={withCaret}
        isTransparentMode={isTransparentMode}
        withBorder={withBorder}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
      />

      {menuItem.subMenu && isOpen && (
        <div
          className={clsx(
            'absolute bg-white py-5 pt-2 rounded-sm shadow top-[62px] -z-10',
            className
          )}
          style={{
            width: `${widthSubMenu || DEFAULT_WIDTH_SUB_MENU}px`,
          }}
        >
          <ul role="menu" aria-orientation="vertical">
            {menuItem.subMenu.map((subMenuItem) => (
              <li
                className="flex items-baseline gap-2 px-5 mt-5 leading-6"
                key={subMenuItem.key}
                role="menuitem"
              >
                <Link
                  href={subMenuItem.url || EMPTY_URL}
                  rel={subMenuItem.isExternal ? 'noopener' : ''}
                  target={subMenuItem.isExternal ? '_blank' : '_self'}
                  data-testid={`nav-${subMenuItem.key}`}
                  locale={subMenuItem.locale}
                  onClick={(e) =>
                    onSubMenuClick && onSubMenuClick(subMenuItem, e)
                  }
                >
                  {subMenuItem.label || t(subMenuItem.key)}{' '}
                  {subMenuItem.hasNewTag && <NewTag className="ml-2" />}
                </Link>
                {subMenuItem.isSelected && (
                  <Image
                    className="mt-[5px] mr-4"
                    src={checkmarkIcon}
                    alt={t('checkmark-icon-alt')}
                    aria-hidden="true"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}
